<template>
  <div class="container" v-title :data-title="$t('i18n.details')" id="details">
    <div id="outer-title">{{ $t("i18n.details") }}</div>
    <div class="flex">
      <div class="left">
        <div class="elevator-info">
          <div class="information">
            <label>{{ $t("i18n.elevatorNo") }}:</label>
            <span>{{ data.number }}</span>
            <label>{{ $t("i18n.projectName") }}:</label>
            <span>{{ data.projectName }}</span>
          </div>
          <div class="elevator-img middle fr">
            <div class="floorImg">{{ data.floor }}</div>
            <img
              v-if="!data.runImg"
              class="elevatorImg"
              src="../../../public/images/elevator_closed02.png"
            />
            <img
              v-else
              class="elevatorImg"
              :src="require('../../../public/images/' + data.runImg)"
            />
            <div class="absolute">
              <img
                v-if="data.signalIntensity"
                class="signalImg"
                :src="
                  require('../../../public/images/' +
                    data.signalIntensity +
                    '.png')
                "
              />
              <div class="up-area">
                <img
                  v-if="data.flow == '10'"
                  class="direction-up"
                  src="../../assets/flowupgreen.png"
                />
              </div>
              <img
                v-if="data.maintenanceStatus == 20"
                src="../../assets/recondition.png"
              />
              <img
                v-else-if="data.faultStatus == 20 && data.isHandled == 20"
                src="../../assets/faultHandling2.png"
              />
              <img
                v-else-if="data.faultStatus == 20 && data.isHandled != 20"
                src="../../assets/malfunction.png"
              />
              <div v-else class="floor-ind">{{ data.floor }}</div>
              <div class="down-area">
                <img
                  v-if="data.flow == '01'"
                  class="direction-down"
                  src="../../assets/flowdowngreen.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="innerImg">
          <div
            class="leftItem item"
            v-for="item in innerLeftImgData"
            :key="item"
            :style="item.style"
          >
            <span
              @click="
                dialogTableVisible = true;
                dialogTitle = item.name;
              "
              v-for="node in item.child"
              :key="node"
            >
              {{ $t("i18n." + node) }}
            </span>
          </div>
          <div
            class="rightItem item"
            v-for="item in innerRightImgData"
            :key="item"
            :style="item.style"
          >
            <span
              @click="
                dialogTableVisible = true;
                dialogTitle = item.name;
              "
              v-for="node in item.child"
              :key="node"
            >
              {{ $t("i18n." + node) }}
            </span>
          </div>
        </div>
      </div>
      <div class="right">
        <div id="table-list" v-for="(item, i) in tableArr" :key="i">
          <h4>{{ $t("i18n." + tableArrEn[i]) }}</h4>
          <el-table :data="tableData[item]" border style="width: 100%">
            <el-table-column
              width="120"
              align="center"
              prop="name"
              :label="$t('i18n.name')"
            ></el-table-column>
            <el-table-column
              width="140"
              v-if="i != 0 && i != tableArr.length - 1"
              align="center"
              :label="$t('i18n.terminalValid')"
            >
              <template #default="scope">
                <div v-if="scope.row.valid == '有效'" style="color: red">
                  {{ scope.row.valid }}
                </div>
                <div v-else>{{ scope.row.valid }}</div>
              </template>
            </el-table-column>
            <el-table-column align="center" :label="$t('i18n.value')">
              <template #default="scope">
                <div :style="{ color: scope.row.color }">
                  <span v-if="i != 0 && i != 2">
                    {{ scope.row.escapeValue }}:
                  </span>
                  {{ scope.row.value }}
                  <span v-if="scope.row.unit">({{ scope.row.unit }})</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <el-dialog :title="$t('i18n.' + dialogTitle)" v-model="dialogTableVisible">
      <el-table :data="gridData">
        <el-table-column
          prop="date"
          :label="$t('i18n.sensorType')"
        ></el-table-column>
        <el-table-column prop="name" :label="$t('i18n.temp')"></el-table-column>
        <el-table-column
          prop="address"
          :label="$t('i18n.humidity')"
        ></el-table-column>
        <el-table-column
          prop="address"
          :label="$t('i18n.machineRoomIntrusion')"
        ></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import socket from "../../../public/js/socket";
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useRoute } from "vue-router";
export default {
  name: "Detectioncou",
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const state = reactive({
      data: "",
      dialogTableVisible: false,
      gridData: [],
      dialogTitle: "",
      innerLeftImgData: [
        {
          child: ["nodeOne", "nodeTwo", "nodeThree"],
          name: "controller",
          style: { top: "90px", left: "45px", width: "170px" },
        },
        {
          child: ["nodeOne", "nodeTwo", "nodeThree"],
          name: "manMachineInteraction",
          style: { top: "252px", left: "45px", width: "188px" },
        },
      ],
      innerRightImgData: [
        {
          child: ["nodeOne", "nodeTwo", "nodeThree"],
          name: "host",
          style: { top: "75px", right: "53px", width: "200px" },
        },
        {
          child: ["nodeOne", "nodeTwo", "nodeThree"],
          name: "car",
          style: { top: "290px", right: "53px", width: "200px" },
        },
        {
          child: ["nodeOne", "nodeTwo", "nodeThree"],
          name: "shaft",
          style: { top: "500px", right: "53px", width: "200px" },
        },
      ],
      tableData: {},
      validData: {},
      tableArr: [
        "电流/电压",
        "主控板输入/输出端子状态",
        "轿顶输入状态",
        "轿顶输出状态",
      ],
      tableArrEn: [
        "currentVoltage",
        "inOutTerminalState",
        "carTopInput",
        "carTopOutput",
      ],
    });

    const init = async () => {
      let id = route.query.id;
      let { data } = await proxy.$api.supervision.detectionOne(id);
      data.websocketType = 10;
      state.data = data;
      socket.set(
        state.data,
        (ele) => {
          fillF5Data(ele[0]);
          fillTerminal(ele[1]);
        },
        "F5"
      );
    };

    const fillF5Data = (data) => {
      if (!Array.isArray(data) || data.length == 0) {
        return;
      }
      var arrTotal = [];
      var nameArr = [
        "X1功能选择",
        "X3功能选择",
        "X4功能选择",
        "X5功能选择",
        "X6功能选择",
        "X7功能选择",
        "X12功能选择",
        "X13功能选择",
        "Y1功能选择",
        "Y2功能选择",
        "Y3功能选择",
      ];
      var arr = data.filter((row) => row.name.indexOf("功能选择") > -1);
      arr.map((item, index) => {
        if (nameArr.indexOf(item.name) > -1) {
          item.color =
            item.value.search(/\.常开/g) != -1
              ? "green"
              : item.value.search(/\.常闭/g) != -1
              ? "blue"
              : "";
          item.valid = state.validData["主控板输入/输出端子状态"][index].value;
          arrTotal.push(item);
        }
      });
      state.tableData["主控板输入/输出端子状态"] = arrTotal;
    };

    const fillTerminal = (data) => {
      if (!Array.isArray(data) || data.length == 0) {
        return;
      }
      state.tableArr.forEach((item) => {
        if (item.indexOf("主控板") == -1) {
          let arr = data.filter((row) => row.functionCode.indexOf(item) > -1);
          if (item.indexOf("轿顶输入状态") > -1) {
            var array = [];
            arr.map((row) => {
              if (row.name == "光幕1" || row.name == "开门到位1") {
                row.valid = row.value;
                row.name = row.name.substring(0, row.name.length - 1);
                var splitFCode = row.functionCode.split(":")[2].split("-");
                if (splitFCode.length == 3) {
                  // var functionCode = splitFCode[0] + "-" + splitFCode[1];
                  var bit = splitFCode[2];
                }
                var bits = prefixInteger(row.escapeValue.toString(2), 4)
                  .split("")
                  .reverse(); //变成数组，反转数组元素
                var v = bits[bit * 1];
                row.value = v == 0 ? v + ": 常开" : "1: 常闭";
                row.color = v == 0 ? "green" : "blue";
                array.push(row);
                state.tableData[item] = array;
              }
            });
          } else if (item.indexOf("轿顶输出状态") > -1) {
            var arrNew = arr.filter((row) => row.name.indexOf("门锁信号") > -1);
            arrNew.splice(0, 1);
            state.tableData[item] = arrNew;
          } else {
            state.tableData[item] = arr;
          }
        } else {
          let arr = data.filter(
            (row) =>
              row.functionCode.indexOf("主控板输入端子状态") > -1 &&
              row.functionCode.split(":").length == 3
          );
          var arr1 = data.filter(
            (row) =>
              row.functionCode.indexOf("主控板输出端子状态") > -1 &&
              row.functionCode.split(":").length == 3
          );
          state.validData["主控板输入/输出端子状态"] = arr.concat(arr1);
        }
      });
    };

    const prefixInteger = (num, length) => {
      //slice 从已有的数组返回选定的元素
      return (Array(length).join("0") + num).slice(-length);
    };

    init();

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss">
#details {
  margin-bottom: 80px;
  .left {
    width: 5 * $width + 24;
    flex-shrink: 0;
    .elevator-info {
      background: $gray2_color;
      padding: 20px 15px;
      font-size: $font + 2;
      .information {
        display: inline-block;
        padding: 25px 20px;
        width: 2 * $width + 90;
        span {
          display: block;
          margin: 23px 0;
          color: $blue1_color;
        }
      }
      .elevator-img {
        padding: 15px 40px 30px 0;
        background: $white;
        width: 2 * $width;
        position: relative;
        text-align: center;
        .floorImg {
          width: 72px;
          height: 36px;
          margin: 0 auto;
          color: $red_color;
          font-size: $font;
          line-height: 28px;
          background: url("../../assets/dingbuloucengbj.png") no-repeat 4px
            center;
        }
        .elevatorImg {
          margin-top: -13px;
          width: 117px;
        }
        .absolute {
          position: absolute;
          top: 20px;
          right: 0;
          width: 60px;
          height: 100%;
          .signalImg {
            float: right;
            margin-right: 10px;
            margin-top: -10px;
            width: 20px;
          }
          .up-area {
            margin-top: 0px;
            line-height: 130px;
            padding-left: 5px;
          }
          .up-area,
          .down-area {
            width: 31px;
            height: 80px;
          }
          .direction-up {
            animation: donghuadown 1.5s infinite;
          }
          .direction-down {
            animation: donghuaup 1.5s infinite;
          }
          .floor-ind {
            width: 31px;
            height: 27px;
            line-height: 27px;
            color: $white;
            background: $green_color;
          }
        }
      }
    }
    .innerImg {
      position: relative;
      width: 100%;
      height: 720px;
      margin-top: 30px;
      background: $table_color url("../../assets/detection/eleFrame.png")
        no-repeat center center;
      background-size: 202px 600px;
      .item {
        position: absolute;
        span {
          cursor: pointer;
          display: block;
          text-align: center;
          line-height: 30px;
          color: $white;
          background-color: #3694f4;
          width: 86px;
          height: 30px;
          margin: 10px 0;
          border-radius: 10px;
          z-index: 2;
        }
      }
      .leftItem {
        background: url("../../assets/detection/leftGreenLine.png") no-repeat
          center right;
      }
      .rightItem {
        background: url("../../assets/detection/rightGreenLine.png") no-repeat
          center left;
        span {
          margin-left: auto;
        }
      }
    }
  }
  .right {
    width: 100%;
    padding-left: 20px;
    .el-table {
      margin: 20px 0 20px;
    }
  }
}
</style>
